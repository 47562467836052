import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import React from 'react';

import { DisclosuresQuery } from '../../../../graphql-types';
import Benefits from '../../../../static/MedSupp/svg/benefits.inline.svg';
import Choices from '../../../../static/MedSupp/svg/choices.inline.svg';
import Doctor from '../../../../static/MedSupp/svg/doctor.inline.svg';
import Guaruntee from '../../../../static/MedSupp/svg/guarantee.inline.svg';
import Look from '../../../../static/MedSupp/svg/look.inline.svg';
import Portable from '../../../../static/MedSupp/svg/portable.inline.svg';
import Renew from '../../../../static/MedSupp/svg/renew.inline.svg';
import BreadcrumbAdapter from '../../../components/BreadcrumbAdapter';
import FindARep from '../../../components/FindARep';
import LeadForm from '../../../components/Forms/LeadGen/LeadForm';
import { LeadFormMode } from '../../../components/Forms/LeadGen/types';
import Layout from '../../../components/Layout';
import BenefitsListItemAdapter from '../../../components/MedicareSupplement/BenefitsListItemAdapter/BenefitsListItemAdapter';
import { Hero } from '../../../components/MedicareSupplement/index';
import List from '../../../components/MedicareSupplement/List/List';
import Section from '../../../components/MedicareSupplement/Section/Section';
import TableAdapter from '../../../components/MedicareSupplement/Table/TableAdapter';
import PageIdentifier from '../../../components/PageIdentifier';
import MedSuppAdapter from '../../../components/PillTabsAdapter/MedSuppAdapter/MedSuppAdapter';
import RichText from '../../../components/RichText';
import { WLH2, WLText } from '../../../design-library';
import { WLContainer } from '../../../design-library';

const aDefault = [
  {
    tabLabel: 'A',
    visible: true,
    heading: 'Plan A is for people who prefer',
    preferences: [
      {
        text: 'Basic coverage',
      },
      {
        text: 'Lower monthly premiums',
      },
      {
        text: 'Paying for out-of-pocket expenses as you go',
      },
    ],
    subHeading: 'How Plan A Works',
    text: [
      'Plan A is a basic coverage plan from which the other Medicare Supplement plans build upon.',
      'This plan helps cover out-of-pocket expenses that Medicare doesn’t cover, such as Medicare Part A coinsurance and hospital costs, Medicare Part B coinsurance or copayment, first three pints of blood and Part A hospice care coinsurance or copayment.',
      'However, it does not cover the coinsurance for skilled nursing facility care, the Part A deductible ($1,632 in 2024), the Part B deductible ($240 in 2024), Part B excess charges or foreign travel emergencies – meaning you’ll need to pay for these services out-of-pocket in the event you’ll need them.',
    ],
  },
  {
    tabLabel: 'C',
    visible: false,
    heading: 'Plan C is for people who prefer',
    preferences: [
      {
        text: 'No out-of-pocket surprises',
      },
      {
        text: 'Strong protection',
      },
    ],
    subHeading: 'How Plan C Works',
    text: [
      'This plan provides coverage of the Part B deductible ($240 in 2024), in exchange for a higher monthly premium.',
      'HIt also provides ample coverage (with exception to Part B excess charges), including coverage of the Part A deductible ($1,632 in 2024) and the Part B deductible ($240 in 2024), leaving no room for unexpected expenses.',
      'Additionally, Plan C covers 80% of foreign travel emergencies. Emergency coverage exists for the first 60 days of travel outside of the country.',
      'Plan C is available for those who are Medicare-eligible on or before Jan. 1, 2020. ',
    ],
  },
  {
    tabLabel: 'F *',
    visible: true,
    heading: 'Plan F is for people who prefer',
    preferences: [
      {
        text: 'Paying upfront',
      },
      {
        text: 'Higher monthly premiums',
      },
      {
        text: 'No out-of-pocket surprises',
      },
      {
        text: 'Strong protection',
      },
    ],
    subHeading: 'How Plan F Works',
    text: [
      'The most comprehensive coverage option, Plan F helps you pay the Medicare Part A deductible ($1,632 in 2024), coinsurance and other costs incurred from Medicare Part A.',
      `It also pays the cost incurred from Medicare Part B, including the annual deductible.`,
      `It covers the remaining Medicare approved expenses, so you won't need to pay anything.`,
      `Plan F is available to those eligible for Medicare on or before 01/01/2020.`,
    ],
  },
  {
    tabLabel: 'G',
    visible: true,
    heading: 'Plan G is for people who prefer',
    preferences: [
      {
        text: 'Paying upfront',
      },
      {
        text: 'Higher monthly premiums',
      },
      {
        text: 'No out-of-pocket surprises',
      },
      {
        text: 'Strong protection',
      },
    ],
    subHeading: 'How Plan G Works',
    text: [
      `Our most popular plan, Plan G helps you pay the Medicare Part A deductible ($1,632 in 2024), coinsurance and other costs incurred from Medicare Part A.`,
      `However, it does not help you pay all costs. You still need to pay the Medicare Part B deductible ($240 in 2024) before Plan G begins paying benefits.`,
      `After the Medicare Part B deductible is met, it covers the remaining Medicare approved expenses.`,
    ],
  },
  {
    tabLabel: `Hi-Ded G†`,
    visible: true,
    heading: 'High-Deductible Plan G is for people who prefer',
    preferences: [
      {
        text: 'Paying as you go',
      },
      {
        text: 'Low monthly premiums even if it means there could be high out-of-pocket costs',
      },
      {
        text: '"Safety net" type coverage',
      },
    ],
    subHeading: 'How High-Deductible Plan G Works',
    text: [
      'Before High-Deductible Plan G begins paying benefits, you will need to pay Medicare-covered costs such as deductibles, coinsurance and copayments ($2,800 in 2024).',
      'After you reach $2,800 the plan kicks in and helps you pay the remaining Medicare Part A deductible, coinsurance and other costs incurred from Medicare Part A.',
      `However, it does not help you pay the cost incurred from Medicare Part B, you will need to pay the Medicare Part B deductible ($240 in 2024).`,
      `It covers the remaining Medicare approved expenses, so you won't need to pay anything else.`,
    ],
  },
  {
    tabLabel: 'N',
    visible: true,
    heading: 'Plan N is for people who prefer',
    preferences: [
      {
        text: 'Paying some costs upfront and some as you go',
      },
      {
        text: 'A mix of monthly premiums and copays',
      },
      {
        text: 'Occasional out-of-pocket expenses',
      },
      {
        text: 'Strong protection',
      },
    ],
    subHeading: 'How Plan N Works',
    text: [
      'Plan N helps you pay the Medicare Part A deductible ($1,632 in 2024), coinsurance and other costs incurred from Medicare Part A.',
      'However, it does not help you pay all costs incurred from Medicare Part B. You still need to pay the Medicare Part B deductible ($240 in 2024).',
      'After the $240 deductible is met, you will pay a copay of up to $20 each time you go to the doctor, and a copay of up to $50 if you go to the emergency room.',
    ],
  },
  {
    tabLabel: 'D',
    visible: false,
    heading: 'Plan D is for people who prefer',
    preferences: [
      {
        text: 'Strong protection',
      },
      {
        text: 'Foreign travel emergency coverage',
      },
      {
        text: 'Cover Part A deductible',
      },
    ],
    subHeading: 'How Plan D Works',
    text: [
      'Plan D provides ample coverage, including coverage of the Part A deductible ($1,632 in 2024) and associated coinsurance and hospital costs.',
      'Plan D does 80% of foreign travel emergencies',
    ],
  },
  {
    tabLabel: 'B',
    visible: false,
    heading: 'Plan B is for people who prefer',
    preferences: [
      {
        text: 'Basic coverage',
      },
      {
        text: 'Lower monthly premiums',
      },
      {
        text: 'Paying for out-of-pocket expenses such as copayments and coinsurance ',
      },
    ],
    subHeading: 'How Plan B Works',
    text: [
      'Plan B provides basic coverage, which includes coverage of the Part A deductible [($1,632 in 2024)] and Part A coinsurance and hospital out-of-pocket expenses up to 365 days after Medicare benefits are used up. ',
      'However, it does not cover the coinsurance for skilled nursing facility care, the Part B deductible [($240 in 2024)], Part B excess charges or foreign travel emergencies – meaning you’ll need to pay for these services out-of-pocket in the event you’ll need them.',
    ],
  },
];

const listData = [
  {
    svg: <Choices />,
    heading: 'Feel Good About Your Choices',
    copy: 'Our Medicare Supplement plan has no restrictive networks. You can visit the providers of your  choice, including primary care physicians, specialists and hospitals that accept Medicare patients. Most providers are paid automatically, so you won’t have to worry about filing a claim.',
  },
  {
    svg: <Doctor />,
    heading: 'Go Directly to Your Doctors',
    copy: 'No pre-certification or pre-authorization is needed for care. You may visit any provider that accepts Medicare. A physician referral may be required for specialist, diagnostic, laboratory, or other facility care.',
  },
  {
    svg: <Benefits />,
    heading: 'Benefits Stay the Same',
    copy: 'You always know what your benefits are with this standardized plan — plan benefits remain the same year after year.',
  },
  {
    svg: <Guaruntee />,
    heading: '12-month Rate Guarantee',
    copy: 'No rate increases for the first 12 months, as long as the premiums are paid.',
  },
  {
    svg: <Portable />,
    heading: 'Portable Coverage',
    copy: 'You are not restricted to use a network of health care providers. If you move or travel, your coverage goes with you.',
  },
  {
    svg: <Renew />,
    heading: 'Guaranteed Renewable',
    copy: 'No worries of reduced benefits or canceled coverage for the life of the certificate, as long as the premiums are paid. Premiums are based on your attained age and will change on the certificate anniversary date. WoodmenLife reserves the right to change premiums.',
  },
  {
    svg: <Look />,
    heading: '30 Days Free Look',
    copy: 'Return any certificate for any reason within 30 days after the receipt for a full refund of all premiums paid.',
  },
];

interface MedSuppPageProps extends PageProps {
  data: DisclosuresQuery;
}

const MedSuppPage: React.FC<MedSuppPageProps> = ({ location, data }) => {
  const [tabsData, setTabsData] = React.useState(aDefault);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stateValue, setStateValue] = React.useState('');
  const [showPage, setShowPage] = React.useState(false);
  const [showLeadForm, setShowLeadForm] = React.useState(false);
  let gridWidth = 3;
  const [approved, setApproved] = React.useState([
    'AL',
    'AZ',
    'AR',
    'CO',
    'FL',
    'GA',
    'IA',
    'IL',
    'IN',
    'KY',
    'LA',
    'MI',
    'MS',
    'NC',
    'NJ',
    'OH',
    'OK',
    'OR',
    'PA',
    'SC',
    'TN',
    'TX',
    'WV',
  ]);

  React.useEffect(() => {
    let tempArray = aDefault;

    if (stateValue === 'PA') {
      tempArray = tabsData.map((e, i) => {
        if (i === 1 || i === 6) {
          return {
            ...e,
            visible: false,
          };
        }
        if (i === 7) {
          return {
            ...e,
            visible: true,
          };
        }
        return e;
      });
    }

    if (stateValue === 'NJ') {
      tempArray = tabsData.map((e, i) => {
        if (i === 1 || i === 6) {
          return {
            ...e,
            visible: true,
          };
        }
        if (i === 7) {
          return {
            ...e,
            visible: false,
          };
        }
        return e;
      });
    }

    setTabsData(tempArray);
  }, [stateValue]);

  const listItems = React.useMemo(() => {
    return listData.map((benefit, index) => {
      if (index === 5) {
        if (stateValue === 'FL') {
          return {
            ...benefit,
            copy: `No worries of reduced benefits or canceled coverage for the life of the certificate, as long as the premiums are paid. Your certificate may be canceled in the event of non-payment or material misrepresentation
            on the application. Premium changes will occur on the first certificate renewal date which coincides with or follows the next certificate anniversary date. Any change will apply to all insureds in the same class who are insured under the same plan who reside in your state. WoodmenLife reserves the right to change premiums.`,
          };
        }
        return {
          ...benefit,
          copy: `No worries of reduced benefits or canceled coverage for the life of the certificate, as long as the premiums are paid. Premiums are based on your attained age and will change on the certificate anniversary date. WoodmenLife reserves the right to change premiums.`,
        };
      }
      return benefit;
    });
  }, [stateValue]);

  const [approvedMarket, setApprovedMarket] = React.useState(['OR']);

  const heroImage = getImage(data.file?.childImageSharp?.gatsbyImageData);

  const disclosures = React.useMemo(() => {
    let disclosure;
    const { nodes } = data.allContentfulDisclosure;
    if (!data) return null;

    nodes.every(
      (
        node: {
          id: string;
          name?: string | null;
          states?: Array<string | null> | null;
          disclosureText?: {
            raw?: string | null;
          } | null;
        },
        id: number,
      ) => {
        return node.states?.every((state) => {
          if (state === stateValue) {
            disclosure = node.disclosureText;
            return false;
          } else disclosure = null;
          return true;
        });
      },
    );
    return disclosure;
  }, [data, stateValue]);

  const handleStateOptions = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setStateValue(e.target.value);
    approved.find((state) => state === e.target.value) === e.target.value
      ? setShowPage(true)
      : setShowPage(false);

    if (
      approvedMarket.find((state) => state === e.target.value) ===
      e.target.value
    ) {
      setShowPage(false);
      onOpen();
    } else {
      setShowLeadForm(false);
    }
  };

  const ref = React.useRef(
    typeof document !== `undefined` ? document.createElement('div') : null,
  );

  React.useEffect(() => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

    if (stateValue === 'OK') {
      gridWidth = 2;
    }
  }, [ref, stateValue]);

  return (
    <>
      <Layout
        seo={{
          title: 'Medicare Supplement Insurance Plans',
          description: `Learn more about Medicare Supplement Insurance and compare plans. Let us help you find the plan that's right for you. Get a free quote today.`,
          slug: '',
          keywords: `Medicare Supplement Insurance`,
        }}
        currentPath={location.pathname}
      >
        {stateValue && (
          <>
            <WLContainer width="EXTRA_WIDE" align="start">
              <WLText
                lineHeight={4}
                paddingX={{ base: 4, lg: 8 }}
                pt={{ base: 1.5, tablet: 2.5 }}
                pb={{ base: 2, tablet: 3 }}
                bg="#F4F1EF"
                fontSize={'12px'}
                w="100%"
                opacity="50%"
                textAlign={{ base: 'center', tablet: 'left' }}
              >
                <WLText
                  fontSize={{ base: '10px', tablet: '12px' }}
                  display={{ base: 'block', tablet: 'inline' }}
                >
                  {stateValue === 'IL' || stateValue === 'NC'
                    ? `Medicare Supplement Insurance underwritten by Woodmen of the World Life Insurance Society`
                    : `WoodmenLife Medicare Supplement Insurance Plans`}
                </WLText>
              </WLText>
            </WLContainer>
          </>
        )}

        <WLContainer
          width="EXTRA_WIDE"
          align="start"
          paddingX={{ base: 4, lg: 8 }}
          paddingTop={{ base: 2, lg: 2 }}
          paddingBottom={{ base: 3, lg: 3 }}
          justifyContent="center"
        >
          <BreadcrumbAdapter
            currentPage="Medicare Supplement Insurance"
            parent={{
              breadcrumbTitle: 'Insurance',
              slug: '/insurance/',
              parentPage: {
                breadcrumbTitle: 'Home',
                slug: '/',
              },
            }}
          />
        </WLContainer>
        <WLContainer w="EXTRA_WIDE" mb={0}>
          <VStack spacing={0} position="relative" w="full">
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
              <ModalOverlay
                bg="rgba(255, 255, 255, 0.90)"
                backdropFilter="auto"
                backdropBlur="2px"
              />
              <ModalContent maxHeight="calc(100vh - 7.5rem)">
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody overflow="auto">
                  <LeadForm
                    mode={LeadFormMode.GENERIC}
                    refPage="Medicare Supplement"
                    submitLabel={() => 'Submit'}
                    compact={false}
                    heading={() => <WLH2>Let’s Start a Conversation</WLH2>}
                    disclosures={() => (
                      <>
                        <strong>
                          All products may not be available in all states.
                        </strong>
                        <br />
                        This is a solicitation of insurance and an agent may
                        contact you. By submitting the above request, I consent
                        to receive phone calls from a licensed insurance
                        producer on behalf of Woodmen of the World Life
                        Insurance Society (WoodmenLife) and its affiliates at
                        the phone number(s) above regarding WoodmenLife products
                        and services. I understand that I can contact a Sales
                        Representative directly to make a purchase.
                      </>
                    )}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
            <Hero
              showPage={showPage}
              handleStateOptions={handleStateOptions}
              stateValue={stateValue}
            />
            {showPage && (
              <VStack
                spacing={14}
                position="relative"
                w="full"
                ref={ref}
                scrollMarginTop={'200px'}
              >
                <Section
                  overline={`medicare supplement insurance`}
                  heading="Helping You Manage Your Health Care Expenses"
                  copy={`When it comes to Medicare Supplement insurance, choosing a plan that fits your  specific needs is important. 
              ${
                stateValue === 'TX'
                  ? 'Woodmen of the World Life Insurance Society'
                  : 'WoodmenLife'
              } offers several plan options that can help you fill some of the gaps in Medicare coverage.  
              You can choose any provider that accepts Medicare,  at a location that is convenient for you. 
              Plus, when you purchase a WoodmenLife Medicare Supplement plan, you become a member, which grants you access
              to exclusive member benefits.`}
                />
                <Section>
                  <SimpleGrid
                    columns={{ base: 1, md: stateValue === 'OK' ? 2 : 3 }}
                    spacing={'48px'}
                    paddingX={{ base: '19px', md: '24px' }}
                  >
                    {stateValue !== 'OK' && (
                      <VStack>
                        <Heading
                          fontSize="24px"
                          fontWeight={'bold'}
                          color="wl-blue.navy"
                        >
                          Fill the Gaps
                        </Heading>
                        <Text
                          as="p"
                          fontSize="sm"
                          color="#3D3C3C"
                          lineHeight={'22px'}
                        >
                          Medicare provides beneficial coverage for health
                          related expenses, but it does not cover all health
                          care expenses. There are a number of gaps in Medicare
                          coverage that you need to pay for out‑of‑pocket or
                          with private insurance.
                        </Text>
                        <Text
                          as="p"
                          fontSize="sm"
                          color="#3D3C3C"
                          lineHeight={'22px'}
                        >
                          A <strong>Medicare Supplement plan</strong> is a
                          health insurance certificate (also called Medigap)
                          sold by a private insurance company to help fill in
                          some of those gaps.
                        </Text>
                      </VStack>
                    )}
                    <VStack>
                      <Heading
                        fontSize="24px"
                        fontWeight={'bold'}
                        color="wl-blue.navy"
                      >
                        Know Your Options
                      </Heading>
                      <Text
                        as="p"
                        fontSize="sm"
                        color="#3D3C3C"
                        lineHeight={'22px'}
                      >
                        Although private insurance companies provide Medicare
                        Supplement coverage, Medicare Supplement insurance plans
                        are strictly regulated by both the federal and the state
                        government.
                      </Text>
                      <Text
                        as="p"
                        fontSize="sm"
                        color="#3D3C3C"
                        lineHeight={'22px'}
                      >
                        It’s important to make an informed decision about what’s
                        right for you. Before you apply for a plan,&nbsp;
                        <strong>get to know what the coverage includes</strong>.
                        Then choose a Medicare Supplement plan that best fits
                        your needs.
                      </Text>
                    </VStack>
                    <VStack>
                      <Heading
                        fontSize="24px"
                        fontWeight={'bold'}
                        color="wl-blue.navy"
                      >
                        Take Care of Yourself
                      </Heading>
                      <Text
                        as="p"
                        fontSize="sm"
                        color="#3D3C3C"
                        lineHeight={'22px'}
                      >
                        <strong>A Medicare Supplement plan:</strong>
                      </Text>
                      <Text
                        as="p"
                        fontSize="sm"
                        color="#3D3C3C"
                        lineHeight={'22px'}
                      >
                        Helps you manage and budget your health care expenses
                        with predictability and stability.
                      </Text>
                      <hr style={{ width: '100%' }} />
                      <Text
                        as="p"
                        fontSize="sm"
                        color="#3D3C3C"
                        lineHeight={'22px'}
                      >
                        Helps pay some of the out-of-pocket costs for
                        Medicare-approved services.
                      </Text>
                      <hr style={{ width: '100%' }} />
                      <Text
                        as="p"
                        fontSize="sm"
                        color="#3D3C3C"
                        lineHeight={'22px'}
                      >
                        {' '}
                        Works with Medicare to provide more coverage to you.
                      </Text>
                    </VStack>
                  </SimpleGrid>
                  {heroImage && (
                    <GatsbyImage
                      imgStyle={{ position: 'absolute', inset: 0 }}
                      image={heroImage}
                      alt={'A young girl playing with her grandparents'}
                    />
                  )}
                </Section>
                <Section heading={'What’s Great About Plans with WoodmenLife'}>
                  <List
                    items={listItems}
                    resourceName="items"
                    itemComponent={BenefitsListItemAdapter}
                  />
                </Section>
                <Section
                  heading="Choose Your Own Plan"
                  copy="WoodmenLife offers Medicare Supplement plans A, F, G, and N with varying amounts of coverage — Plan A 
  provides basic benefits, and Plan F offers more comprehensive coverage. Premiums will vary according to the 
  amount of coverage provided by each plan. You may be eligible for a household premium discount. Reference 
  the application for more details. Here are the benefits included in each plan:"
                >
                  <MedSuppAdapter tabs={tabsData} />
                </Section>
                <Section>
                  <TableAdapter stateValue={stateValue} />
                  <Box
                    w="full"
                    fontSize={'12px'}
                    color="#797877"
                    padding={'24px 0 80px 0'}
                    textAlign="left"
                  >
                    <Text>
                      <sup>*</sup>{' '}
                      {`Plan F available for people first eligible
                      for Medicare before 2020 only.`}
                    </Text>
                    <Text>
                      <sup>†</sup>{' '}
                      {`Before High Deductible Plan G pays benefits, you pay your
                      Medicare-covered costs (deductibles, coinsurance and
                      copayments) up to the certificate’s deductible amount of $2,800
                      in 2024.`}
                    </Text>
                    <Text>
                      <sup>‡</sup>{' '}
                      {`Plan N requires a $20 copay for office visits and a $50
                      copay for emergency room visits. Copays do not count toward
                      the annual Part B deductible.`}
                    </Text>
                    {stateValue === 'TX' && (
                      <Text>
                        <sup>§</sup>{' '}
                        {`For Plans F and G the amount may not exceed any charge limitations established by Medicare.`}
                      </Text>
                    )}
                  </Box>
                </Section>
              </VStack>
            )}
          </VStack>

          {showPage && (
            <>
              <Box paddingX={['8px', null]} margin="0 auto" width="100%">
                <FindARep
                  content={
                    stateValue === 'FL'
                      ? `Our Representatives who are licensed insurance agents can help you purchase Medicare Supplement insurance or retirement products. Together you can prepare a customized plan that best meets your specific needs and budget.`
                      : `Our Representatives can help you purchase Medicare Supplement insurance or retirement products. Together you can prepare a customized plan that best meets your specific needs and budget.`
                  }
                />
              </Box>
              {disclosures && (
                <Box
                  fontSize={'12px'}
                  color="#797877"
                  padding={'80px 0 80px 0'}
                  sx={{
                    h4: {
                      color: '#3D3C3C',
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      fontWeight: 'bold',
                      marginTop: '48px',
                      marginBottom: '16px',
                    },
                    a: {
                      textDecoration: 'none',
                      fontSize: '12px',
                      color: '#00549F',
                    },
                  }}
                >
                  <RichText value={disclosures as RenderRichTextData<never>} />
                </Box>
              )}
              <Box width="full" maxW="90vw" textAlign="left">
                <PageIdentifier
                  webNumber={'Web 14 R-8/23'}
                  secondaryWebNumber={`617357${
                    stateValue === 'TX'
                      ? '_TX'
                      : stateValue === 'FL'
                      ? '_FL'
                      : stateValue === 'OH'
                      ? '_OH'
                      : ''
                  }-1`}
                  lastUpdated={''}
                />
              </Box>
            </>
          )}
        </WLContainer>
      </Layout>
    </>
  );
};

export const query = graphql`
  query disclosures {
    allContentfulDisclosure {
      nodes {
        id
        name
        states
        disclosureText {
          raw
        }
      }
    }
    file(name: { eq: "MedSupp-midsection" }) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: DOMINANT_COLOR)
      }
    }
  }
`;

export default MedSuppPage;
